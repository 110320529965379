<template>
  <div class="mx-box">
    <p style="margin:0 0 15px 0;text-align:center;" class="van-hairline--bottom">
      电子凭证
    </p>
    <div style="text-align:center;">
      <van-image
        :src="qr"
      />
    </div>
    <p style="margin:7px 0 0 0;text-align:center;" class="van-hairline--bottom">
      <small>请将二维码给商家展示，勿自行扫码</small>
    </p>
    <p style="margin:7px 0 0 0;text-align:center;" class="van-hairline--bottom">
      <small>总共 {{ order.credentialTotalQuantity }}张， 已用 {{ order.credentialUsedQuantity }} 张</small>
    </p>
  </div>
  <div class="mx-box">
    <p style="margin:0 0 15px 0;text-align:center;" class="van-hairline--bottom">
      凭证有效期
    </p>
    <p style="margin:7px 0 0 0;text-align:center;" class="van-hairline--bottom">
      <small>从 {{ order.credentialEffectStartTime }}</small>
    </p>
    <p style="margin:7px 0 0 0;text-align:center;" class="van-hairline--bottom">
      <small>到 {{ order.credentialEffectEndTime }}</small>
    </p>
  </div>
  <div class="mx-box">
    <p style="margin:0 0 15px 0;text-align:center;" class="van-hairline--bottom">
      商品
    </p>
    <div class="mx-info">
      <van-card
        :num="order.productQuantity"
        :price="order.productPrice"
        :desc="order.productSkuText"
        :title="order.productName"
        :thumb="order.productImage"
      >
        <template #tags v-if="order.targetDate">
          <van-tag plain type="danger">{{ order.targetDate }}</van-tag>
        </template>
      </van-card>
    </div>
  </div>
  <van-cell-group title="核销记录" v-if="order && order.writeOffs && order.writeOffs.length">
    <div class="listbox" v-for="(item,index) in order.writeOffs" :key="index">
      <div class="headbox">
        <div class="userphone">商家电话：{{item.phone}}</div>
        <div class="time">{{item.createTime}}</div>
      </div>
      <div class="remark">{{item.remark}}</div>
    </div>
  </van-cell-group>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import QRCode from 'qrcode'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const state = reactive({
      orderId: useRoute.query.orderId,
      order: {},
      qr: ''
    })
    const getOrder = () => {
      post('/order.get', {
        orderId: state.orderId
      }).then(res => {
        // res.data.writeOffs = [
        //   { phone: '111', createTime: '111', remark: '111' }
        // ]
        state.order = res.data
        const www = window.location.protocol + '//' + window.location.host + '/order/verify?orderId=' + state.orderId
        console.dir(www)
        QRCode.toDataURL(www).then(url => {
          state.qr = url
        })
      })
    }
    const init = () => {
      getOrder()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getOrder
    }
  }
}
</script>

<style scoped>
.mx-box {
  margin:7px;
  padding:15px;
  background:#fff;
  border-radius:5px;
}
.mx-info {
  padding:0 0;
}
.listbox{
  background: #FFFFFF;
  position: relative;
  padding:var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
}
.listbox::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: var(--van-padding-md);
  bottom: 0;
  left: var(--van-padding-md);
  border-bottom: 1px solid var(--van-cell-border-color);
  transform: scaleY(.5);
}
.listbox .headbox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
.listbox .headbox .time{
  color: #999999;
}
.listbox .remark{
  margin-top: 8px;
  text-align: justify;
  font-size: 12px;
  color: #999999;
}
</style>
